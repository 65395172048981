import router from '../router/Routes'

export default {
  state: {
    collaboratorRegister: {
      rowInformations: null,
      collaborator: {},
      rm: {},
      apportions: [],
    },
    generalSearch: '',
    accessToken: null,
    loggingIn: false,
    selectedMonth: '',
    errorMenssage: null,
    pathName: '',
    navCurrentIndex: '1',
    isNavBarVisibible: false,
    pageSize: Number(process.env.VUE_APP_ITEMS_PER_PAGE),
    accessLevel: 'PMO',
    navItems: [
      {
        index: '1',
        disabled: false,
        vText: 'Importação',
        route: '/',
        accessLevel: ['pmo'],
      },
      {
        index: '2',
        disabled: false,
        vText: 'Projetos',
        route: '/projects',
        accessLevel: ['pmo', 'manager'],
      },
      {
        index: '3',
        disabled: false,
        vText: 'Exportação',
        route: '/export',
        accessLevel: ['pmo'],
      },
      {
        index: '4',
        disabled: false,
        vText: 'Usuários',
        route: '/users',
        accessLevel: ['pmo'],
      },
    ],
    navLink: [
      {
        index: '1',
        routeName: 'home',
      },
      {
        index: '2',
        routeName: 'projects',
      },
      {
        index: '2',
        routeName: 'details',
      },
      {
        index: '3',
        routeName: 'export',
      },
      {
        index: '3',
        routeName: 'costtrackingpage',
      },
      {
        index: '4',
        routeName: 'users',
      },
    ],
  },
  mutations: {
    loginStart: (state) => {
      state.loggingIn = true
    },
    loginStop: (state, errorMenssage) => {
      state.loggingIn = false
      state.loginError = errorMenssage
    },
    logout: (state) => {
      state.accessToken = null
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
    },
    updatePathName: (state, path) => {
      state.pathName = path
    },
    updateNavCurrentIndex: (state, index) => {
      state.navCurrentIndex = index
    },
    updateNavVisibility: (state, newState) => {
      state.isNavBarVisibible = newState
    },
    updateSelectedMonth: (state, newState) => {
      sessionStorage.setItem('selectedMonth ', newState)
      state.selectedMonth = newState
    },
    updateGeneralSearch: (state, value) => {
      state.generalSearch = value
    },
    updatePageSize: (state, value) => {
      state.pageSize = value
    },
    updateCollaboratorRegister: (state, value) => {
      state.collaboratorRegister = value
    },
    updateCollaboratorStep: (state, value) => {
      state.collaboratorStep = value
    },
  },
  getters: {
    getSelectedMonth: (state) => state.selectedMonth,
    getSessionSelectedMonth: () => {
      return sessionStorage.getItem('selectedMonth ')
    },
    getCurrentTab: (state) => state.currentTab,
    getPathName: (state) => state.pathName,
    getAccessToken: (state) => state.accessToken,
    getNavLink: (state) => state.navLink,
    getNavItems: (state) => state.navItems,
    getNavCurrentIndex: (state) => state.navCurrentIndex,
    getNavBarVisibility: (state) => state.isNavBarVisibible,
    getGeneralSearch: (state) => state.generalSearch,
    getPageSize: (state) => state.pageSize,
    getCollaboratorRegister: (state) => state.collaboratorRegister,
  },
  actions: {
    updatePageSize({ commit }, pageSize) {
      commit('updatePageSize', pageSize)
    },
    doToken({ commit }, token) {
      localStorage.setItem('accessToken', token)
      commit('updateAccessToken', token)
    },
    doLogin({ commit }, loginData) {
      commit('loginStart')
      localStorage.setItem('accessLevel', loginData.accessLevel)
      localStorage.setItem('accessUserName', loginData.userName)
      localStorage.setItem('accessUserEmail', loginData.userEmail)
      localStorage.setItem('accessUserCostCenters', loginData.userCostCenters)
      commit('loginStop', null)
    },
    updateCollaboratorStep({ commit }, newState) {
      sessionStorage.setItem('collaboratorStep', newState)
    },
    doCollaborator({ commit }, collaboratorData) {
      const newColabData = {
        rowInformations: collaboratorData,
        collaborator: {},
        rm: {},
        apportions: [],
      }
      sessionStorage.setItem('collaboratorData', JSON.stringify(newColabData))
      commit('updateCollaboratorRegister', newColabData)
    },
    updateStoreCollaboratorRegister({ commit }) {
      const collabData = JSON.parse(sessionStorage.getItem('collaboratorData'))
      commit('updateCollaboratorRegister', collabData)
    },
    updateCollaboratorRegister({ commit, getters }, { prop, newState }) {
      const newColabData = {
        ...getters.getCollaboratorRegister,
        [prop]: newState,
      }
      commit('updateCollaboratorRegister', newColabData)
      sessionStorage.setItem('collaboratorData', JSON.stringify(newColabData))
    },
    fetchAccessToken({ commit }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'))
    },
    logout({ commit }) {
      localStorage.removeItem('accessLevel')
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessUserName')
      localStorage.removeItem('accessUserEmail')
      localStorage.removeItem('accessUserCostCenters')
      commit('logout')
      router.go()
    },
    updateNavCurrentIndex({ commit, getters }, name) {
      const navItems = getters.getNavLink
      const newNav = navItems.filter(
        ({ routeName }) => name?.toLowerCase() === routeName
      )
      if (newNav.length !== 0) {
        const index = newNav[0].index
        commit('updateNavCurrentIndex', index)
      }
    },
    updateNavVisibility({ commit }, newState) {
      commit('updateNavVisibility', newState)
    },
    updateSelectedMonth({ commit }, newState) {
      commit('updateSelectedMonth', newState)
    },
    setGeneralSearch({ commit }, newState) {
      commit('updateGeneralSearch', newState)
    },
  },
}
