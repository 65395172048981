import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router/Routes'
import storeConfig from './store/index'
import i18n from './i18n'
import TreeSelect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/pt-br'
import 'element-ui/lib/theme-chalk/index.css'
import mixins from './mixins'
import VueMask from 'v-mask'
import InputNumber from '@/components/InputNumber/InputNumber.vue'

Vue.config.productionTip = false

Vue.use(Vuex)
Vue.use(ElementUI, { locale })
Vue.mixin(mixins)
Vue.use(VueMask)

export const store = new Vuex.Store(storeConfig)

Vue.component('treeselect', TreeSelect)
Vue.component('el-input-number', InputNumber)

const app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')

if (window.Cypress) {
  // be visible only during e2e tests
  window.app = app
}
