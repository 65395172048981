<template>
  <el-footer class="footer"
    >Relatórios Financeiros <span>{{ version }}</span></el-footer
  >
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      version: '',
    }
  },
  created() {
    this.version = process.env.VUE_APP_VERSION
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
