import api from '../instance'

export function validateToken(token, email) {
  return api.get(`/accessLevel?email=${email}`, {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export async function auth(token) {
  return await api.post(`/auth`, { token })
}

export async function logout() {
  return await api.post(`/logout`)
}
