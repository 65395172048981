import { mapActions } from 'vuex'
import dayjs from '../utils/dayjs'

const mixins = {
  methods: {
    ...mapActions(['updatePageSize']),
    handleFormatValue(value) {
      let formatValue = value ? value : 0
      return new Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }).format(formatValue)
    },
    numberFormatterCurrency(num) {
      const value = Number(num)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      return `${value.split('.')[0].replace(',', '.')},${value.split('.')[1]}`
    },
    handleIsValidDate(date) {
      return dayjs(date).isValid()
    },
    handleMonthAndYearFormatter(date) {
      return this.handleIsValidDate(date) ? dayjs(date).format('MM/YYYY') : '-'
    },
    handleFormatEndOfMonth(date) {
      return this.handleIsValidDate(date)
        ? dayjs(date).endOf('month').format('DD/MM/YYYY')
        : '-'
    },
    handleFormatDate(date) {
      return this.handleIsValidDate(date)
        ? dayjs(date).format('DD/MM/YYYY')
        : '-'
    },
    handleTextFormat(text) {
      return text ? text.substring(0, 50) + '...' : 'Nome não definido...'
    },
    handleFormatData(text) {
      return text?.length > 0 ? text : '-'
    },
    handleFormatRegister(register) {
      const registerOptions = {
        0: () => '-',
        11: this.handleFormatCPF,
        14: this.handleFormatCNPJ,
        default: (register) => register,
      }

      const returnValue =
        registerOptions[register?.length || 0] || registerOptions.default

      return returnValue(register)
    },
    handleFormatInputRegister(register) {
      const registerOptions = {
        11: this.handleFormatCPF,
        14: this.handleFormatCNPJ,
        default: (register) => register,
      }

      const clearRegister = register?.replace(/\D/g, '')

      const returnValue =
        registerOptions[clearRegister?.length || 'default'] ||
        registerOptions.default

      return returnValue(clearRegister)
    },
    handleFormatCPF(cpf) {
      cpf = cpf?.replace(/\D/g, '')

      return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    },
    handleFormatCNPJ(cnpj) {
      cnpj = cnpj?.replace(/\D/g, '')

      return cnpj.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      )
    },
    handlePageSizes(interval, maxLength) {
      let sizes = []

      for (let size = interval; size <= maxLength; size += interval) {
        sizes.push(size)
      }
      if (maxLength % interval) {
        sizes.push(maxLength)
      }

      return sizes
    },
    handleIconText({ row, column }) {
      const isTrue = Boolean(row[column.property])
      const prop = [column.property]
      const tooltipText = {
        isCorrelate: {
          true: 'Item com união',
          false: 'Item sem união',
        },
        justification: {
          true: 'Item justificado',
          false: 'Item não justificado',
        },
        activity: {
          true: 'Atividade relatada',
          false: 'Sem atividade relatada',
        },
        hasEntry: {
          true: 'Item com lançamentos',
          false: 'Item sem lançamentos',
        },
        provider: {
          true: 'Fornecedor encontrado',
          false: 'Fornecedor não encontrado',
        },
        hasLeasing: {
          true: 'Lançamento realizado',
          false: 'Lançamento não realizado',
        },
        tripsGroupId: {
          true: 'Lançamento possui anexos',
          false: 'Lançamento não possui anexos',
        },
        default: {
          true: 'Item verdadeiro',
          false: 'Item falso',
        },
      }
      return tooltipText[prop][isTrue] || tooltipText.default[isTrue]
    },
    handleIconControl({ row, column }) {
      return !!row[column.property]
    },
    handleSizeChange(pageSize) {
      this.updatePageSize(pageSize)
    },
    handleHasInformation(info) {
      return info?.length > 0 ? info : 'não informado'
    },
    handleCompareDates(a, b) {
      return this.handleFormatDate(a).includes(String(b).toLowerCase())
    },
    handleCompareStrings(a, b) {
      return String(a).toLowerCase().includes(String(b).toLowerCase())
    },
    handleCompareValues(a, b) {
      return String(this.numberFormatterCurrency(a)).includes(String(b))
    },
    handleCompareLedgerBookValues({ ledgerBookValue }, b) {
      return String(this.numberFormatterCurrency(ledgerBookValue)).includes(
        String(b)
      )
    },
    compareRegister(a, b) {
      return String(a)
        .toLowerCase()
        .includes(
          String(b)
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, '')
        )
    },
    handlePageContent(search, searchColumns, buffer) {
      const compareFunctions = {
        String: this.handleCompareStrings,
        LedgerBookValue: this.handleCompareLedgerBookValues,
        Date: this.handleCompareDates,
        Number: this.handleCompareValues,
        Register: this.compareRegister,
      }

      if (search?.length > 0) {
        return buffer.filter((item) => {
          for (let column of searchColumns) {
            if (
              compareFunctions[column.propType](item[column.propName], search)
            ) {
              return item
            }
          }
        })
      } else {
        return buffer
      }
    },
    handleSortByName(list) {
      return list.sort((a, b) => {
        const nameA = a.name.toUpperCase()
        const nameB = b.name.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
  },
}

export default mixins
