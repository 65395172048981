import axios from 'axios'
import ElementUI from 'element-ui'

let baseURL = process.env.VUE_APP_ROOT_API

const instance = axios.create({
  baseURL,
  withCredentials: true,
  responseType: 'json',
})

instance.interceptors.response.use(
  function (response) {
    if (process.env.VUE_APP_NOTIFICATIONS === 'true') {
      ElementUI.Notification({
        message: response.config.url,
        type: 'success',
        duration: 3000,
        position: 'bottom-right',
      })
    }
    return response
  },
  function (error) {
    let previousErrorCode = error.response.status
    let message = 'Erro de autenticação.'
    if (previousErrorCode === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('accessUserName')
      if (!window.location.pathname.includes('login')) {
        message = 'Sessão expirada. Redirecionando...'
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      }
      ElementUI.Notification({
        message: message,
        type: 'error',
      })
    } else if (
      previousErrorCode !== 401 &&
      process.env.VUE_APP_NOTIFICATIONS === 'true'
    ) {
      ElementUI.Notification({
        title: `${error.config.url}`,
        message: error.response.data.error,
        type: 'error',
        sshowClose: true,
        position: 'bottom-right',
      })
    }
    return Promise.reject(error)
  }
)

export default instance
