import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from '../main'
import ElementUI from 'element-ui'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/pages/HomePage/Home.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/LoginPage/Login.vue'),
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('@/pages/ProjectsPage/Projects.vue'),
  },
  {
    path: '/export',
    name: 'Export',
    component: () => import('@/pages/ExportPage/Export.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/pages/UsersPage/UsersPage.vue'),
  },
  {
    path: '/details/:id/:rubric',
    name: 'Details',
    component: () => import('@/pages/DetailsPage/Details.vue'),
  },
  {
    path: '/entries/:projectId',
    name: 'EntriesPage',
    component: () => import('@/pages/EntriesPage/EntriesPage.vue'),
    children: [
      {
        path: ':rubricType/:paramIdType/:providerId/:costcenterCode',
        name: 'EntriesRHPage',
        component: () =>
          import('@/pages/EntriesTablePage/EntriesTablePage.vue'),
      },
      {
        path: ':rubricType/:paramIdType/:providerId/:costcenterCode',
        name: 'EntriesTrainingsPage',
        component: () =>
          import('@/pages/EntriesTablePage/EntriesTablePage.vue'),
      },
      {
        path: ':rubricType/:paramIdType/:providerId/:costcenterCode',
        name: 'EntriesLeasingPage',
        component: () =>
          import('@/pages/EntriesTablePage/EntriesTablePage.vue'),
      },
    ],
  },
  {
    path: '/costTracking/:id/:frId/:tabName',
    name: 'CostTrackingPage',
    component: () => import('@/pages/CostTrackingPage/CostTrackingPage.vue'),
  },
  {
    path: '/newCollaboratorInRelated',
    name: 'NewCollaboratorInRelated',
    component: () =>
      import('@/pages/NewCollaboratorInRelated/NewCollaboratorInRelated.vue'),
  },
  {
    path: '*',
    redirect: () => {
      ElementUI.Notification({
        title: 'Página não encontrada!',
        message: 'Não foi possível completar sua solicitação.',
        type: 'warning',
        sshowClose: true,
        position: 'bottom-left',
      })
      return { path: '/' }
    },
    name: '404Page',
    component: () => import('@/pages/404Page/404Page.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  store.commit('updatePathName', to.path)
  store.dispatch('fetchAccessToken')

  const accessToken = store.getters.getAccessToken
  const isNavBarVisible = !localStorage.getItem('accessToken')
  store.dispatch('updateNavVisibility', !isNavBarVisible)

  if (to.fullPath === '/') {
    if (!accessToken) {
      next('/login')
    }
  } else if (to.fullPath === '/login') {
    if (accessToken) {
      next('/')
    }
  } else {
    if (!accessToken) {
      next('/login')
    }
  }

  next()
})

export default router
