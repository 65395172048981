<template>
  <el-menu
    class="menu"
    mode="horizontal"
    background-color="#fff"
    text-color="#252525"
    active-text-color="#E87305"
    :default-active="getCurrentNav"
    :router="true"
  >
    <img class="menu-img" :src="navLogo" alt="PMO" @click="goToHome()" />

    <el-menu-item
      class="nav-buttons"
      v-for="item in navItems"
      :key="`nav-button-${item.index}`"
      :index="item.index"
      :disabled="item.disabled"
      :route="item.route"
    >
      {{ item.vText }}
    </el-menu-item>

    <div class="menu-right-items">
      <el-dropdown @command="handleCommand">
        <div class="drop-menu">
          <el-avatar class="drop-menu-avatar">{{ avatarName }}</el-avatar>
          <div>
            <span>{{ userName }}</span>
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
        </div>
        <el-dropdown-menu class="sub-drop-menu" slot="dropdown">
          <el-dropdown-item class="sub-drop-menu-item" command="logout"
            >Sair</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-menu>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import navLogo from '/public/assets/PMO_Header.svg'
import { logout } from '@/service/accessLevel'

export default {
  name: 'NavBar',
  data() {
    return {
      navItems: '',
      avatarName: '',
      userName: '',
      navLogo: navLogo,
    }
  },
  methods: {
    ...mapGetters(['getNavItems', 'getNavCurrentIndex']),
    ...mapActions(['logout', 'updateNavCurrentIndex']),
    goToHome() {
      const Home = {
        pmo: 'Home',
        manager: 'Projects',
      }

      const hasOnHome =
        this.$route.name === Home[localStorage.getItem('accessLevel')]
      if (!hasOnHome) {
        this.$router.push({ name: Home[localStorage.getItem('accessLevel')] })
      }
    },
    async handleCommand(command) {
      if (command === 'logout') {
        try {
          await logout()
          this.logout()
        } catch (error) {
          this.$message({
            showClose: true,
            type: 'error',
            sshowClose: true,
            message: error.response.data,
          })
        }
      }
    },
    formatedAvatarName() {
      return this.userName
        .split(' ')
        .map((name) => name[0])
        .join('')
    },
  },
  created() {
    const userName = localStorage.getItem('accessUserName')

    this.navItems = this.getNavItems().map((e) => ({
      ...e,
      disabled: !e.accessLevel.includes(
        localStorage.getItem('accessLevel').toLowerCase()
      ),
    }))
    this.userName = userName
    this.avatarName = this.formatedAvatarName()
  },
  computed: {
    getCurrentNav() {
      return this.getNavCurrentIndex()
    },
  },
}
</script>

<style src="./style.scss" lang="scss" scoped></style>
