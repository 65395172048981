<template>
  <el-main class="global">
    <NavBar v-if="isNavBarVisibility" id="nav" />
    <transition name="slide-fade" mode="out-in" @before-enter="scrollTop">
      <router-view id="util-view" />
    </transition>
    <Footer v-if="getFooter" />
  </el-main>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Footer from './components/Footer/Footer.vue'
import NavBar from './components/NavBar/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Footer,
  },
  methods: {
    ...mapGetters([
      'getNavBarVisibility',
      'getSelectedMonth',
      'getSessionSelectedMonth',
    ]),
    ...mapActions(['updateNavCurrentIndex', 'updateSelectedMonth']),
    scrollTop() {
      document.getElementById('nav').scrollIntoView()
    },
    handleSelectMonth() {
      const systemDate = this.getSessionSelectedMonth()
        ? new Date(this.getSessionSelectedMonth())
        : new Date()
      this.updateSelectedMonth(systemDate)
    },
  },
  computed: {
    isNavBarVisibility() {
      const name = this.$route.name
      this.updateNavCurrentIndex(name)
      return this.getNavBarVisibility()
    },
    getFooter() {
      const name = this.$route.path
      return name === '/login' ? false : true
    },
  },
  created() {
    this.handleSelectMonth()
  },
}
</script>

<style lang="scss">
@import '@/global.scss';

.page {
  background-color: $neutral-5 !important;
  padding: 10px 35px !important;

  &.modal {
    height: calc(100vh - 24px);
    overflow: hidden;
  }
}
</style>
