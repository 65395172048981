import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export default (date) =>
  dayjs(date)
    .tz(process.env.VUE_APP_TIMEZONE || 'America/Sao_Paulo')
    .utc()
